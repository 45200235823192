<template lang="pug">
v-app.v-theme--nx
    v-main
        router-view
        Notifications

</template>

<script setup lang="ts">
import {
    computed, ref, onMounted, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import type { BorderRadii, ThemeColors } from '~/interfaces/theme';
import { DEFAULT_LOCALE } from '~/constants/internationalization';
import { getLanguageFromLocale } from '~/i18n';
import { useThemeStore } from '~/store/theme';
import Notifications from '~/components/feedback/Notifications.vue';
import { useAuthenticationStore } from '~/store/authentication';

const authenticationStore = useAuthenticationStore();

const themeStore = useThemeStore();

const i18n = useI18n();
i18n.locale.value = getLanguageFromLocale(navigator.language) || DEFAULT_LOCALE;

const customStyleElement = ref<HTMLStyleElement | undefined>(undefined);

const customCss = computed<string>(() => (`
.v-theme--light {
    ${Object.keys(themeStore.colors).map((key) => `--v-theme-${key}: ${themeStore.colors[key as keyof ThemeColors]?.r},
    ${themeStore.colors[key as keyof ThemeColors]?.g}, ${themeStore.colors[key as keyof ThemeColors]?.b}`).join(';\n')};
    ${Object.keys(themeStore.borderRadii).map((key) => `--v-theme-${key}: ${themeStore.borderRadii[key as keyof BorderRadii]}px`).join(';\n')};

    --v-theme-spacing-default: 24px;
    --v-theme-spacing-half: 12px;
    --v-theme-spacing-quarter: 6px;
    --v-theme-spacing-eighth: 3px;

    .v-field {
        border-radius: var(--v-theme-border-radius-inputs);
    }
    .v-card {
        border-radius: var(--v-theme-border-radius-cards);
    }
    .v-btn {
        border-radius: var(--v-theme-border-radius-buttons);
    }
    .v-alert {
        border-radius: var(--v-theme-border-radius-alerts);
    }

    .border-radius {
        border-radius: var(--v-theme-border-radius-inputs);
    }

    .bg-on-surface {
        background-color: rgb(var(--v-theme-on-surface));
    }

    .flex-spacing-default-h {
        column-gap: var(--v-theme-spacing-default);
    }
    .flex-spacing-default-v {
        row-gap: var(--v-theme-spacing-default);
    }
    .flex-spacing-half-h {
        column-gap: var(--v-theme-spacing-half);
    }
    .flex-spacing-half-v {
        row-gap: var(--v-theme-spacing-half);
    }
}`));

onMounted(() => {
    const el = document.createElement('style');
    customStyleElement.value = document.head.appendChild(el);
    customStyleElement.value.innerHTML = customCss.value;
});

watch(() => customCss.value, () => {
    if (customStyleElement.value) customStyleElement.value.innerHTML = customCss.value;
});

authenticationStore.bindEvents();

watch(authenticationStore, () => {
    if (authenticationStore.user && authenticationStore.user?.language) i18n.locale.value = authenticationStore.user?.language ?? '';
});

</script>

<style lang="scss">
:root {
    --font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    --v-theme-color-neutral: rgb(181, 181, 181);
}
html, body {
    font-family: var(--font-family);
}

.p-relative {
    position: relative;
}

ol {
    padding-left: 20px;
}

body .bg-grey-lighten-2 {
    color: inherit !important;
}

.hatched {
    background: repeating-linear-gradient(45deg, rgba(var(--v-theme-surface), .5),
    rgba(var(--v-theme-surface), .5) 10px, rgba(var(--v-theme-background), .5) 10px,
    rgba(var(--v-theme-background), .5) 20px);
}

.code {
    background: #e0e0e0;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    padding: var(--v-theme-spacing-half);
}
a {
    color: rgb(var(--v-theme-primary));
    text-decoration: none;
}

.v-list-item--hide-title {
    .v-list-item-title {
        display: none;
    }
}

.v-btn.v-btn--less-line-height {
    .v-btn__content {
        line-height: 0.9rem !important;
    }
}

.v-expansion-panel__shadow {
    box-shadow: 1px 1px 24px 0px rgba(37, 42, 49, 0.08) !important;
}

.v-card {
    padding: 8px !important;

    .v-card-item__content {
        .v-card-title {
            font-weight: 600 !important;
            white-space: normal;
        }
    }
}

.v-card-item {
    padding-bottom: 0 !important;
    // padding: 1.5rem 1rem 0 1rem !important;
}

.file-upload  {
    margin-top: 2rem;
}

.toggle-row-reverse {
    .v-selection-control {
        flex-direction: row-reverse !important;
        justify-content: space-between !important;
    }
    .v-label {
        padding: 0 1.5rem 0 0 !important;
    }
}

.v-field__overlay {
    background-color: white !important;
}

.page-container {
    padding: 2.5rem 3rem !important;
}

.v-selection-control__input {
    &:hover {
        box-shadow: 0px 0px 6px 3px rgba(92,92,92,0.45);
        width: auto;
        height: auto;
    }
}

.v-breadcrumbs-item {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline !important;
    white-space: nowrap;
    max-width: 180px;
}

.v-slide-group {

    &.v-tabs--density-default {
        --v-tabs-height: auto;
    }

    .v-btn {
        text-transform: none;
        font-weight: 400;
        height: auto;
        border-bottom: 1px solid rgba(92,92,92,0.3);
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;

        &.v-tab--selected {
            .v-btn__content {
                color: #009efa;
            }
        }

        &__content {
            letter-spacing: normal;
        }
    }

    .v-tab__slider {
        height: 2px;
        background-color: #009efa;
        bottom: -1px;
    }
}

.v-btn.v-btn--density-default {
    height: auto;
    padding: 0.25rem 1rem;
}

.v-overlay__content {
    .v-card-title {
        white-space: unset;
    }
}

.v-container {
    max-width: 1400px !important;
}

.v-application .v-selection-control__input {
    width: 18px;
    height: 18px;

    &:hover {
        box-shadow: 0px 0px 3px 1px rgba(143, 143, 143, 0.45);
        width: 18px;
        height: 18px;
        border-radius: 2px;
    }
}

@media screen and (min-width: 1360px) {
    .page-container {
        padding: 2.5rem 5rem !important;
    }
}

@media screen and (min-width: 1920px) {
    .v-container {
        max-width: 1600px !important;
    }
}
</style>
